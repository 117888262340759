:root {
  --primary-color: white;
  --secondary-color: #0088a6;
  --blue-lis: #092e3b;
  --lis-ligthblue: #00b1e2;
  --aedas-green: #00b176;
  --toastify-color-success: #00b176 !important;
  --toastify-color-error: #b1003b !important;
  --aedas-red: #b1003b;
  --font-pay: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  --lis-title: "lis-title";
  --lis-body: "lis-body";
  --iconColor: black;
  --fc-today-bg-color: #0088a650;
  --iconButtonSelected: #0088a6;
}

#root > div.row > div.w-100 > div > button > svg > path{
  fill:white !important
}


body {
  font-family: var(--lis-title), var(--lis-body);
  background-color: #8080801a !important;
  height: 100%;
  margin: 0;
}

@font-face {
  font-family: "lis-body";
  src: url(/public/Assets/Fonts/Heebo-VariableFont_wght.ttf);
}

@font-face {
  font-family: "lis-title";
  src: url(/public/Assets/Fonts/WorkSans-Medium.ttf);
}

/* .row {
  --bs-gutter-x: 0 !important;
}

.btn_submit{
  color:white !important;
  background-color: var(--blue-lis)!important;
} */

.main-container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 4px;
  width: 90%;
  margin: auto 2rem;
}

.page-title {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  margin-top: 5rem;
  text-transform: uppercase;
}

.button {
  font-family: var(--lis-title) !important;
  font-size: 1rem !important;
  padding: 0.5rem 1rem !important;
  margin: 1rem auto 0 1rem!important;
  width: fit-content;
  text-align: center;
}

.blue-button {
  background-color: var(--blue-lis) !important;
  color: white !important;
}

.grey-button {
  background-color: #e3e3e3!important;
  color: black !important;
}
.MuiDataGrid-cell--withRenderer button.grey-button {
  padding: 0 !important;
  margin: 0 !important;
}

.outlined-button {
  background-color: white !important;
  color: var(--blue-lis) !important;
}

.MuiDataGrid-main {
  font-family: var(--lis-title);
}

@media screen and (min-width: 1680px) {
  .main-container {
    max-width: calc(1680px * 0.9);
    margin: 0 auto;
  }
} 


.fc .fc-col-header-cell-cushion {
  display: inline-block;
  padding: 2px 4px;
  text-decoration: none;
  color: white;
  background:var(--blue-lis)
}




.fc-daygrid-day-number{
  color:black;
  text-decoration: none;
}



 div.fc-view-harness.fc-view-harness-active > div > table > tbody > tr > td{
  border-radius: 0px 0px 18px 18px!important;
 }


div.fc-view-harness.fc-view-harness-active > div > table > thead > tr > th{
  background:var(--blue-lis);
  border-radius: 18px 18px 0px 0px !important;
 }


 .fc-theme-standard .fc-scrollgrid {
  /* border: 1px solid #ddd; */
  /* border: 1px solid var(--fc-border-color, #ddd); */
  border-radius: 20px;
}